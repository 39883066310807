@font-face { font-family: 'SF Pro Rounded'; src: url("/src/assets/fonts/SFProRounded.woff2") format("woff2"), url("/src/assets/fonts/SFProRounded.woff") format("woff"); font-weight: 300; font-style: normal; font-display: swap; }
@font-face { font-family: 'SF Pro Rounded'; src: url("/src/assets/fonts/SFProRounded_Medium.woff2") format("woff2"), url("/src/assets/fonts/SFProRounded_Medium.woff") format("woff"); font-weight: 400; font-style: normal; font-display: swap; }

*{
    font-family: 'SF Pro Rounded' !important;
}
a{
    text-decoration: none;
}
.app-wrapper{
    padding: 0px 50px;
    background: #F5F5F5;
    display: flex;
    min-height: 100vh;
}
.app-sidebar{
    width: 300px;
}
.app-drawer .MuiDrawer-paper{
    width: 300px;
    border-right: none ;
    top: 50px;
    left: 50px;
    height: calc(100vh - 100px);
    overflow: hidden;
    padding: 50px 0;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
}
.app-sidebar .logo{
    width: 100%;
}
.sidebar-logo{
    margin-bottom: 40px;
}
.app-sidebar .scrollbar-container{
    height: 100%;
}
.app-sidebar .sidebar-links{
    height: 100% ;
    display: flex;
    min-height: 400px;
    justify-content: space-between;
    padding: 30px 0;
    padding-bottom: 0;
    /* margin-bottom: 40px; */
    flex-direction: column;
    border-top: 1px solid #efefef;
    position: relative;
}
.app-sidebar .sidebar-links .links{
    display: flex;
    height: calc(100% - 130px);
    border-bottom: 1px solid #efefef;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
    padding-left: 60px;
    padding-bottom: 30px;
}
.app-sidebar .sidebar-links ul:last-of-type{
    position: absolute;
    bottom: 50px;
    width: calc(100% - 110px);
    left: 60px;
}
.app-sidebar .sidebar-links .logout-btn {
    padding: 0 50px;
    margin-top: 0;
    padding-left: 60px;
}
.app-sidebar .sidebar-links a{
    padding: 5px 0;
    width: fit-content;
}
.app-sidebar .sidebar-links a span, .app-sidebar .sidebar-action a{
    transition: 0.2s ease-in-out;
    font-weight: 400;
}
.app-sidebar .sidebar-links a:hover span, .app-sidebar .sidebar-action a:hover span{
    color: #bf5af2 !important;
}
.activity-table .MuiCardContent-root{
    padding: 0;
}
.login-page-wrapper{
    min-height: 100vh;
}
.login-page-wrapper .logo-wrapper{
    margin-bottom: 114px;
    padding: 0 !important;
}
.login-page-wrapper.forgot-pass .card-wrapper{
    padding-top: 130px;
}
.login-page-wrapper.forgot-pass .logo-wrapper{
    margin-bottom: 90px ;
}
.google-btn{
    margin-top: 44px;
}
.signing-text{
    margin-bottom: 34px;
}
.signing-text h6{
    font-weight: 400 ;
    line-height: 24px;
}
.logout-btn .MuiButtonBase-root {
    transition: 0.2s ease-in-out;
    border-radius: 6px;
}
.logout-btn:hover .MuiButtonBase-root {
    background-color: rgba(255, 0, 0, 0.1);
}
.login-form input{
    color: #000 !important;
    background-color: #F5F5F5 !important;
    border-radius: 5px;
    height: 12px;
    font-weight: 400;
}
.login-form fieldset{
    border-radius: 5px !important;
}
.forgot-pass-form{
    margin-bottom: 100px;
}
.header-actions .MuiButtonBase-root{
    padding: 6px 20px !important;
    font-size: 15px;
}
/* .login-form button{
    background-color: #bf5af2 !important;
    color: #fff ;
} */
.login-form .MuiInputAdornment-root button{
    padding: 4px;
}
.login-form button .MuiCircularProgress-root {
    color: #fff !important;
}
.dashboard-card-container{
    display: grid;
    grid-column-gap: 26px;
    grid-template-columns: 1fr 530px 1fr;
}
.dashboard-card-wrapper{
    max-width: unset;
    width: 100%;
}
.dashboard-card-wrapper .MuiCardHeader-root{
    padding: 0 40px;
    padding-top: 24px;
    padding-bottom: 16px;
}
.dashboard-card-wrapper .MuiCardContent-root{
    padding: 40px;
    padding-top: 2px;
    min-height: 326px;
}
.card-wrapper{
    border-radius: 10px !important;
}
.adduser-card .card-wrapper .MuiCardContent-root{
    max-height: calc(100vh - 178px);
    display: block;
    overflow-y: auto;
}
.adduser-card .user-pannel {
    min-height: 210px;
    display: flex;
    justify-content: center;
}
.adduser-card .card-wrapper .MuiCardContent-root::-webkit-scrollbar{
    width: 5px;
}
.adduser-card .card-wrapper .MuiCardContent-root::-webkit-scrollbar-thumb{
    border-radius: 3px;
    background: #e0e0e0;
}
.adduser-card .MuiPaper-root {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
    margin: 0;
}
.adduser-card .MuiPaper-root .MuiAccordionSummary-content{
    margin: 8px 0;
}
.adduser-card .MuiPaper-root::before{
    background-color: #efefef;
}
.adduser-card .MuiCardContent-root {
    padding: 14px 0;
    padding-bottom: 28px;
}
.adduser-card .MuiTabPanel-root {
    padding: 0 25px;
    height: calc(100% - 50px);
    padding-top: 30px;
}
.cutiee-panel{
    padding-top: 12px !important;
}
.adduser-card .MuiTabPanel-root.cutiees-pannel{
    padding: 0 36px;
    padding-top: 24px;
}
.adduser-card .MuiTabs-root{
    padding: 0 24px;
    min-height: 40px;
}
.adduser-form .MuiFormHelperText-root{
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 3px;
    color: #ff5f5f;
}
.adduser-form .adduser-fields-wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.adduser-form .adduser-fields-wrapper .field-wrapper{
    width: 100%;
}
.adduser-form input, .adduser-form .MuiSelect-select{
    height: 19px !important;
    font-weight: 400;
    font-size: 16px;
    min-height: unset;
    padding: 7px 14px !important;
}
.adduser-form fieldset legend{
    display: none;
}
.adduser-form label{
    color: #000 !important;
    font-size: 12px;
    transform: unset;
    margin-bottom: 5px;
    position: relative !important;
    font-weight: 300;
}
.adduser-form label.date-range-field-label{
    color: #999999 !important;
}
.adduser-form  input{
    height: 18px;
    padding: 9px 14px;
    font-weight: 400;
}
.adduser-form  input, .adduser-form fieldset{
    border-radius: 5px !important;
    color: #000;
    top: 0;
    border-color: #dedede !important;
}
.adduser-form .MuiFormControl-root{
    min-height: 60px;
}
.adduser-form.settings{
    align-items: flex-start;
}
.adduser-form.settings .MuiFormControl-root{
    min-height: unset;
}
.table-wrapper {
    border-radius: 10px;
    overflow: hidden;
    border: unset;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
}
.table-wrapper .MuiDataGrid-footerContainer{
    display: none;
}
.table-wrapper.pagination .MuiDataGrid-footerContainer{
    display: block;
}
.table-wrapper.pagination .MuiTablePagination-toolbar{
    padding: 6px 20px;
}
.table-wrapper .MuiDataGrid-columnHeaders{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle, .table-header-filter span{
    font-weight: 300 !important;
    font-size: 12px;
    color: #747474;
}
.table-wrapper .MuiDataGrid-main .MuiDataGrid-cell{
    font-weight: 300;
    font-size: 14px;
}
.table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeader:first-of-type, .table-wrapper .MuiDataGrid-main .MuiDataGrid-cell:first-of-type {
    padding-left: 45px;
}

.table-wrapper .MuiDataGrid-main .MuiDataGrid-cell:first-of-type{
    font-weight: 400;
    color: #000;
}
.table-grid{
    /* margin-top: 22px; */
    margin-top: 22px;
}

.no-tableHeader{
    margin-top: 30px;
}
.no-tableHeader .table-wrapper{
    max-height: 390px;
}
.cuties-accordion{
    box-shadow: unset !important;
}
.tabs-wrapper .MuiTabs-flexContainer{
    align-items: center;
}
.tabs-wrapper .MuiTab-root {
    height: 30px;
    border-radius: 5px;
    font-weight: 300;
}
.two-fields-wrapper{
    margin-bottom: 24px;
    width: 100%;
    max-width: 100%;
}
.two-fields-wrapper fieldset{
    border: none;
}
.two-fields-wrapper .MuiFormControl-root{
    min-height: unset;
}
.signin-btn{
    margin-top: 18px;
}
.signin-btn button{
    font-weight: 300;
}
.signin-btn .MuiGrid-root{
    margin-left: 0 !important;
}
.date-range-field{
    margin-bottom: 12px;
    margin-left: 12px;
}
.date-range-field .MuiFormControl-root{
    min-height: unset;
}
.date-range-field input{
    font-size: 14px;
}
.super-app-theme--header.actions .MuiDataGrid-columnHeaderTitleContainerContent{
    justify-content: center;
}
.css-8nmu0n-MuiInputBase-input-MuiOutlinedInput-input{
    height: 13px !important;
}
.forgot-line-style {
    text-decoration: none;
}

.two-fields-wrapper fieldset{
    border: none;
}
.two-fields-wrapper .MuiFormControl-root{
    min-height: unset;
}

.cutiee-profle {
    height: 23px;
    width: 23px;
    border-radius: 50%
}
.card-heading{
    font-weight: 300;
    font-size: 12px;
}
.column .card-heading {
    font-size: 0.85rem !important;
    font-weight: 300 !important;
    margin-right: 8px !important;
}
.cuties-accordion .accordion-head h6{
    font-size: 20px;
}
.cuties-accordion .delete-btn{
    width: 32px;
    height: 32px;
}
.cuties-accordion .delete-btn span{
    width: 15px;
    height: 18px;
}
.forgot-password {
    cursor: pointer !important;
    font-weight: 300  !important;
    font-size: 12px  !important;
}
.toggle-btn button{
    width: 51px !important;
    height: 30px !important;
}
.toggle-btn button::after{
    width: 26px !important;
    height: 26px !important;
}
.error-msg{
    color: red !important;
    font-size: 12px !important;
    line-height: normal !important;
    margin-top: 1px !important;
}
.custom-datatable .css-ql19fo-MuiDataGrid-overlayWrapper, .custom-datatable .MuiDataGrid-overlayWrapper.css-1tmxkya{
    position: sticky;
    /* top: 10px; */
    left: 0px;
    width: 0px;
    /* height: 80px; */
    z-index: 4;
}
.dashboard-container{
    padding-top: 22px;
}
.custom-datatable .css-1tmxkya {
    position: sticky;
    top: 74px;
    left: 0px;
    width: 0px;
    height: 80px;
    z-index: 4;
    margin-top: 15px;
}

.filter-table .MuiDataGrid-menuIcon{
    visibility: unset !important;
    width: auto !important;
}
.table-header-filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.table-header-filter span:last-of-type{
    width: 16px;
    height: 16px;
    margin-left: 0;
}
.filter-table .MuiDataGrid-columnHeaderTitleContainerContent{
    width: 100%;
}
.addmin-delicon {
    margin-left: 20px; 
    margin-top: 4px;
    width: 36px !important;
    height: 36px !important;
}

.add-cutiee-border {
    margin-top: 20px;
    padding-top: 30px;
    border-bottom: 1px solid #EFEFEF;
    border-top: 1px solid #EFEFEF;
    padding-bottom: 12px;
    justify-content: space-between;
}
.sidebar-logo{
    display: flex;
    align-items: center;
    padding: 0 50px;
}
.main{
    min-height: 100%;
    margin-top: 50px;
    position: relative;
    width: calc(100% - 300px);
    padding-left: 50px;
}
.card-heading-0{
    font-size: 60px;
}
.card-heading-1{
    font-size: 40px;
}
.card-heading-2{
    font-size: 40px;
}
.card-heading-0, .card-heading-1, .card-heading-2{
    margin-top: 2px;
    font-weight: 300;
}
.card-wrapper .card-details{
    margin-top: 12px;
}
.search-filter{
    width: 260px;
    margin-left: 16px;
}
.header-btn-wrapper{
    display: flex;
    align-items: center;
}
.adduser-card .user-pannel form{
    width: 100%;
}
.adduser-form .two-fields-wrapper label{
    margin: 0;
}
.form-container{
    max-width: 550px;
}

.adduser-card .MuiPaper-root .MuiAccordionSummary-expandIconWrapper svg{
    color: rgba(60,60,67,0.3);
}
.settings-title{
    font-size: 18px;
    color: #000;
}
.MuiCircularProgress-colorPrimary {
    color: #bf5af2
}

.dialog-content{
    max-height: 320px;
}
.dialog-content::-webkit-scrollbar{
    width: 6px;
    border-radius: 3px;
}
.dialog-content::-webkit-scrollbar-thumb{
    width: 6px;
    border-radius: 3px;
}


@media only screen and (max-width: 1679px){
    .dashboard-card-container{
        grid-template-columns: 1fr 420px 1fr;
        grid-column-gap: 20px;
    }
}
@media only screen and (max-width: 1440px){
    .app-wrapper{
        padding: 40px 25px;
    }
    .app-drawer .MuiDrawer-paper{
        top: 40px;
        left: 25px;
        width: 224px;
    }
    .app-sidebar{
        width: 224px;
    }
    .sidebar-logo{
        margin-bottom: 40px;
        padding: 0 34px;
    }
    .app-sidebar .sidebar-links{
        margin-bottom: 30px;
        height: calc(100% - 30px);
    }
    .app-sidebar .sidebar-links .links{
        padding: 0 34px;
    }
    .app-sidebar .sidebar-links .logout-btn{
        padding: 0 34px;
    }
    .app-sidebar .sidebar-links ul:last-of-type{
        left: 34px;
    }
    .main{
        width: calc(100% - 224px);
    }
    .no-tableHeader{
        margin-top: 20px;
    }
    .adduser-card .MuiTabs-root .MuiTabs-scroller {
        overflow: auto !important;
    }
    .adduser-card .MuiTabs-root .MuiTabs-scroller::-webkit-scrollbar{
        
        height: 3px;
        width: 3px;
        border-radius: 2px;
    }
    .adduser-card .MuiTabs-root .MuiTabs-scroller::-webkit-scrollbar-thumb{
        background: #e0e0e0;
    }
    .dashboard-card-container{
        grid-template-columns: repeat(3,1fr);
    }
    .table-grid{
        margin-top: 12px;
    }
    .table-grid .MuiCardContent-root{
        padding-top: 18px;
    }
    .dashboard-container{
        padding-top: 10px;
    }
    .secondmainrow{
        padding-top: 12px !important;
    }
}
@media only screen and (max-width: 1279px){
    .dashboard-card-container{
        grid-column-gap: 14px;
    }
    .main{
        padding-left: 30px !important;
    }
    .card-heading-0{
        font-size: 52px;
    }
    .card-heading-1, .card-heading-2{
        font-size: 36px;
    }
    .dashboard-card-wrapper .MuiCardContent-root{
        min-height: 306px;
    }
}
@media only screen and (max-width: 1024px){
    .login-card .card-wrapper{
        padding: 40px 30px;
        padding-top: 60px;
    }
    .signing-text{
        margin-bottom: 20px;
    }
    .signin-btn{
        margin-top: 14px;
    }
    .google-btn{
        margin-top: 30px;
    }
    .login-form input{
        padding: 14px;
    }
    .login-page-wrapper.forgot-pass .logo-wrapper{
        margin-bottom: 60px;
    }
    .forgot-pass-form{
        margin-bottom: 70px ;
    }
    .login-page-wrapper.forgot-pass .login-card .card-wrapper{
        padding-top: 86px ;
    }
    .card-heading-0{
        font-size: 44px;
    }
    .card-heading-1, .card-heading-2{
        font-size: 30px;
    }
    .dashboard-card-wrapper .MuiCardContent-root{
        padding: 20px;
        padding-top: 0;
        min-height: 258px;
    }
    .dashboard-card-wrapper .MuiCardHeader-root{
        padding: 16px 20px;
    }
    .card-wrapper .card-details{
        margin-top: 10px;
    }
    .card-wrapper .card-details:first-of-type{
        margin-top: 16px;
    }
    h2{
        font-size: 24px !important;
    }
    .app-sidebar .sidebar-links a span, .logout-btn span{
        font-size: 16px;
        margin-left: 0;
    }
    .app-sidebar .sidebar-links a{
        padding: 0px 0;
        margin-bottom: 2px;
    }
    .app-sidebar .sidebar-links .svg-color{
        width: 20px;
        height: 20px;
    }
    .logout-btn .MuiListItemIcon-root{
        margin-right: 0;
    }
    .table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeader:first-of-type, .table-wrapper .MuiDataGrid-main .MuiDataGrid-cell:first-of-type{
        padding-left: 20px;
    }
    .sidebar-logo{
        padding: 0 26px;
        justify-content: space-between;
    }
    .app-sidebar .logo{
        width: 140px;
    }
    .table-wrapper .MuiDataGrid-main .MuiDataGrid-cell{
        font-size: 13px;
    }
    .app-sidebar{
        width: 40px;
        /* transition: 0.3s ease-in-out; */
    }
    .app-sidebar.open{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.45);
        z-index: 10;
        backdrop-filter: blur(5px);
    }
    .main{
        width: calc(100% - 40px);
    }
    .menu-btn{
        background: #fff;
        border-radius: 50%;
        z-index: 999;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: unset;
        color: #bf5af2;
        position: fixed;
        top: 28px;
        left: 20px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    }
    .menu-btn:hover{
        background-color: #fff !important;
    }
    .menu-btn svg{
        font-size: 22px;
    }
    .menu-close-btn{
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 45px;
        right: -20px;
        left: unset;
    }
    .menu-close-btn svg{
        font-size: 30px;
    }
    .app-drawer .MuiDrawer-paper{
        overflow: visible;
    }
    .app-wrapper{
        padding: 28px 20px;
        justify-content: right;
    }
    .action-btn-wrapper button{
        font-size: 14px;
        padding: 6px 16px !important;
    }
    .tabs-wrapper .MuiTab-root{
        font-size: 14px;
    }
    .adduser-form input, .adduser-form .MuiSelect-select{
        font-size: 14px !important;
    }
    .login-page-wrapper button{
        font-size: 15px !important;
        padding: 8px 22px !important;
    }
    .login-page-wrapper button img{
        width: 18px;
        height: 18px !important;
        margin-right: 14px !important;
    }
    .login-page-wrapper .logo-wrapper{
        margin-bottom: 60px;
    }
    .addmin-delicon{
        margin-left: 12px;
    }
    .controls .delete-btn, .addmin-delicon{
        width: 32px !important;
        height: 32px !important;
    }
    .controls .delete-btn span, .addmin-delicon span{
        width: 14px;
        height: 17px;
    }
    .controls .toggle-btn button{
        width: 48px;
        height: 26px;
    }
    .controls .toggle-btn button::after{
        width: 24px;
        height: 24px;
    }
    .adduser-card .card-wrapper .MuiCardContent-root{
        max-height: calc(100vh - 160px);
    }
    .add-cutiee-border{
        margin-top: 12px;
        padding-top: 20px;
    }
}
@media only screen and (max-width: 767px){
    .main{
        padding-top: 36px !important;
        padding-left: 0 !important;
        width: 100%;
    }
    .app-sidebar{
        width: 0;
    }
    .login-card{
        max-width: 380px;
    }
    .dashboard-card-container{
        grid-template-columns: repeat(1,1fr);
        gap: 10px;
    }
    .dashboard-card-wrapper{
        flex-basis: 48%;
    }
    .dashboard-card-container .card-details-wrapper{
        flex-direction: row;
        margin-top: 24px;
        justify-content: space-between;
    }
    .card-wrapper .card-details{
        margin-top: 0 !important;
    }
    .dashboard-card-wrapper .MuiCardContent-root{
        min-height: unset ;
        padding: 0 40px;
        padding-bottom: 20px;
    }
    .dashboard-card-wrapper .MuiCardHeader-root{
        padding: 16px 40px;
    }
    .card-heading-0{
        font-size: 52px;
    }
    .card-heading-1{
        font-size: 44px;
    }
    .card-heading-2{
        font-size: 32px;
    }
    .header-actions .MuiButtonBase-root{
        font-size: 14px;
    }
    .header-actions input{
        font-size: 12px;
        height: 10px !important;
    }
    .table-header .MuiCardContent-root{
        padding-top: 12px;
    }
    .no-tableHeader .MuiCardContent-root{
        padding-top: 0;
    }
    .table-wrapper.pagination .MuiTablePagination-toolbar{
        padding: 0 14px;
    }
    .table-header-wrapper{
        flex-direction: column;
        align-items: start;
        gap: 12px;
    }
    .table-header-wrapper .header-actions{
        width: 100%;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
    }
    .header-actions .search-filter{
        margin-left: 0;
    }
    .adduser-card .MuiTabPanel-root, .adduser-card .MuiTabPanel-root.cutiees-pannel{
        padding: 0 20px;
        padding-top: 16px;
    }
    .adduser-card .MuiTabs-root{
        padding: 0 16px;
    }
    .adduser-form .MuiFormHelperText-root{
        font-size: 10px;
    }
    .tabs-wrapper .MuiTab-root{
        margin-right: 5px;
    }
    .adduser-form .MuiIconButton-root {
        padding: 4px;
    }
    .adduser-form .MuiIconButton-root svg{
        width: 18px;
        height: 18px;
    }
    .dropoff-grid{
        flex-direction: column;
        width: 100% !important;
    }
    .dropoff-grid .drop-off{
        margin-right: 0;
        justify-content: space-between;
    }
    .dropoff-grid .drop-off .MuiBox-root{
        margin-right: 0;
        width: 48%;
    }
    .dropoff-grid .schedule{
        width: 70%;
    }
    .trusted-contacts .adduser-fields-wrapper{
        grid-template-columns: repeat(1,1fr);
        gap: 0;
    }
    .two-fields-wrapper{
        margin-bottom: 16px;
    }
    .cutiees-form .adduser-form{
        padding-bottom: 4px;
        margin-top: 14px;
    }
    .cutiees-form .adduser-form:nth-of-type(2){
        padding-bottom: 24px;
    }
    .cutiees-form .adduser-form h6{
        font-size: 18px;
    }
    .cutiees-header{
        margin-bottom: 16px !important;
    }
    .table-wrapper svg{
        font-size: 20px !important;
    }
    .delete-icon{
        width: 15px !important;
        height: 17px !important;
    }
    .adduser-form .adduser-fields-wrapper.date-range-field-wrapper{
        grid-template-columns: repeat(1,1fr);
        gap: 0;
    }
    .settings-title{
        font-size: 15px;
    }
    .center-settings{
        padding-bottom: 10px !important;
        margin-bottom: 10px !important;
    }
    .form-container{
        max-width: 100%;
    }
    .cuties-accordion .accordion-head h6{
        font-size: 16px;
    }
    .table-grid{
        margin-top: 22px;
    }
    .dashboard-container{
        padding-top: 22px;
    }
    .secondmainrow{
        padding-top: 22px !important;
    }
}
@media only screen and (max-width: 480px){
    .main{
        padding-top: 30px !important;
    }
    .accordion-head h6{
        font-size: 17px;
    }
    .login-page-wrapper .MuiPaper-elevation{
        max-width: unset !important;
    }
    .login-card{
        max-width: unset;
        width: 84%;
        margin: 0 auto;
    }
    .login-card .card-wrapper{
        padding: 40px 24px;
    }
    .signing-text h6{
        font-size: 17px;
        letter-spacing: -0.4px;
    }
    .login-page-wrapper .logo-wrapper{
        margin-bottom: 50px;
    }
    .login-page-wrapper .logo-wrapper .logo{
        width: 180px !important;
    }
    .login-page-wrapper.forgot-pass .login-card .card-wrapper{
        padding-top: 64px;
    }
    .forgot-pass-form{
        margin-bottom: 54px ;
    }
    .login-page-wrapper button{
        font-size: 14px;
        padding: 7px 18px;
    }
    .login-page-wrapper button img{
        width: 16px;
        height: 16px !important;
    }
    .dashboard-card-wrapper .MuiCardHeader-root, .dashboard-card-wrapper .MuiCardContent-root{
        padding: 12px 20px;
    }
    .dashboard-card-wrapper .MuiCardContent-root{
        padding-top: 0;
    }
    .card-heading{
        font-size: 12px;
    }
    .card-heading-0{
        font-size: 44px;
    }
    .card-heading-1{
        font-size: 36px;
    }
    .card-heading-2{
        font-size: 28px;
    }
    .app-drawer .MuiDrawer-paper{
        padding: 34px 0;
        height: calc(100vh - 80px);
    }
    .app-sidebar .sidebar-links{
        height: calc(100% - 20px);
        margin-bottom: 20px;
    }
    .app-sidebar .sidebar-links .links{
        height: calc(100% - 120px);
    }
    .sidebar-logo{
        margin-bottom: 20px;
    }
    .menu-btn{
        width: 32px;
        height: 32px;
        left: 14px;
        top: 22px;
    }
    .menu-close-btn{
        top: 30px;
        left: unset;
        right: -16px;
    }
    .menu-btn svg{
        font-size: 18px;
    }
    .header-actions .MuiButtonBase-root{
        padding: 5px 16px !important;
    }
    .header-actions .MuiInputBase-input{
        padding: 12px !important;
    }
    .app-wrapper{
        padding: 22px 14px;
    }
    .adduser-form{
        flex-direction: column;
    }
    .adduser-form.settings {
        flex-direction: row;
    }
    .adduser-form.settings .MuiFormControl-root{
        margin-bottom: 0;
    }
    .adduser-form .adduser-fields-wrapper{
        grid-template-columns: repeat(1,1fr);
        gap: 0;
    }
    .control-wrapper{
        flex-direction: column;
    }
    .adduser-card .btn-wrapper{
        justify-content: flex-end;
        margin-top: 20px;
    }
    .dropoff-grid .schedule{
        width: 100%;
    }
    .cuties-accordion .MuiAccordionSummary-root{
        min-height: 50px;
    }
    .adduser-form .MuiFormControl-root{
        min-height: unset;
        margin-bottom: 14px;
    }
    .adduser-form .date-range-field .MuiFormControl-root{
        margin-bottom: 0px;
    }
    .adduser-form .MuiFormHelperText-root{
        margin-bottom: 0;
    }
    .adduser-form .two-fields-wrapper .MuiFormControl-root{
        margin-bottom: 0;
    }
}